import React from 'react';
import PropTypes, { object } from 'prop-types';

const PhoneField = ({
  formProps,
  ...props
}) => {
  const transformValue = (formprops) => {
    const phoneNumbers = formprops.values.phoneNumber.replace(/\D/g, '').split('');
    // eslint-disable-next-line prefer-const
    let phoneString = '(   )    -    '.split('');
    phoneNumbers.map((character, index) => {
      if (index < 3) {
        phoneString[index + 1] = character;
      }
      if (index >= 3 && index < 6) {
        phoneString[index + 3] = character;
      }
      if (index >= 6 && index < 10) {
        phoneString[index + 4] = character;
      }
      return character;
    });
    return phoneString.join('');
  };

  return (
    <input
      value={transformValue(formProps)}
      {...props}
    />
  );
};

PhoneField.propTypes = {
  formProps: PropTypes.oneOfType([object]),
};

PhoneField.defaultProps = {
  formProps: {},
};

export default PhoneField;
