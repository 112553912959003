import React from 'react';
import * as Yup from 'yup';
import { StaticQuery, graphql, navigate } from 'gatsby';
import {
  Formik, Form, ErrorMessage, FieldArray,
} from 'formik';

import formatGraphqlData from '../../utils/gatsbyGraphql';
import Request from '../../utils/axios';
import Layout from '../../components/layout';
import Grid from '../../components/grid/grid';
import GridItem from '../../components/grid/item';
import Hero from '../../components/hero/hero';
import Card from '../../components/card/card';
import Loader from '../../components/loader/loader';
import Snackbar from '../../components/snackbar/snackbar';
import PhoneField from '../../components/phonefield/phonefield';
import CustomerIdField from '../../components/customeridfield/customeridfield';

const initialValues = {
  customerName: '',
  customerId: '',
  accountHolderName: '',
  facility: '',
  addressLine1: '',
  addressLine2: '',
  phoneNumber: '',
  phoneExt: '',
  email: '',
  city: '',
  state: '',
  zip: '',
  veterinarianName: '',
  medications: [
    {
      medicationName: '',
      animalName: '',
      prescriptionNumber: '',
      quantity: '',
      message: '',
    },
  ],
};

const schema = Yup.object().shape({
  customerName: Yup.string()
    .required()
    .label('Full Name'),
  customerId: Yup.string()
    .min(7)
    .max(8)
    .matches(/[a-zA-Z]{3}\d{2}/, 'Please enter a valid Customer ID')
    .required()
    .label('Customer ID'),
  accountHolderName: Yup.string()
    .required()
    .label('Individual Account Holder Name'),
  facility: Yup.string()
    .required()
    .label('Facility / Ranch name'),
  addressLine1: Yup.string()
    .required()
    .label('Address Line 1'),
  phoneNumber: Yup.string()
    .required()
    .label('Direct Dial Phone Number'),
  phoneExt: Yup.string()
    .label('Ext. #'),
  email: Yup.string()
    .email()
    .required()
    .label('Email'),
  city: Yup.string()
    .required()
    .label('City'),
  state: Yup.string()
    .required()
    .label('State'),
  zip: Yup.number()
    .min(1)
    .typeError('Please provide a number')
    .required()
    .label('Zip code'),
  veterinarianName: Yup.string()
    .required()
    .label('Veterinarian name'),
  medications: Yup.array()
    .of(
      Yup.object().shape({
        medicationName: Yup.string()
          .required()
          .label('Medication'),
        animalName: Yup.string()
          .required()
          .label('Animal name'),
        prescriptionNumber: Yup.string()
          .required()
          .label('Prescription number'),
        quantity: Yup.number()
          .min(1)
          .typeError('Specify total # of vials, kits, or units')
          .required()
          .label('Quantity'),
        message: Yup.string()
          .required()
          .label('Message'),
      }),
    )
    .min(1)
    .required()
    .label('Medications'),
});

const MedicationSection = (medicationProps) => {
  const { formProps, arrayFieldProps, medications } = medicationProps;
  const { push, remove } = arrayFieldProps;

  const {
    values, touched, errors, handleBlur, handleChange,
  } = formProps;

  const AddMedicationBtn = () => (
    <Grid>
      <GridItem sm={12}>
        <div className="form-group">
          <button
            type="button"
            className="c-button--secondary float-right"
            onClick={() => push({ ...initialValues.medications[0] })}
          >
            Add Medication
          </button>
        </div>
      </GridItem>
    </Grid>
  );

  return values.medications.map((medication, key) => {
    const basicConditional = touched.medications
      && errors.medications
      && touched.medications[key]
      && errors.medications[key];

    return (
      <Card key={key.toString()}>
        {key === 0 && <h4>Medications</h4>}
        {key !== 0 && (
          <button type="button" onClick={() => remove(key)} className="c-remove-medication" />
        )}
        <Grid>
          <GridItem sm={6}>
            <div
              className={`form-group ${
                basicConditional
                  && touched.medications[key].medicationName
                  && errors.medications[key].medicationName
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor={`medicationName-${key}`}>Medication *</label>
              <select
                name={`medications[${key}].medicationName`}
                onBlur={handleBlur}
                onChange={handleChange}
                className="form-control"
                defaultValue=""
              >
                <option value="" disabled hidden>
                  Select medication
                </option>
                {medications.map((item, index) => (
                  <option value={`${item.name} ${item.presentation}`} key={index.toString()}>
                    {`${item.name} ${item.presentation}`}
                  </option>
                ))}
              </select>
              <ErrorMessage
                component="div"
                className="error-message"
                name={`medications[${key}].medicationName`}
              />
            </div>
            <div
              className={`form-group ${
                basicConditional
                  && touched.medications[key].animalName
                  && errors.medications[key].animalName
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor={`animalName-${key}`}>Animal Name or Species *</label>
              <input
                type="text"
                className="form-control"
                id={`animalName-${key}`}
                onBlur={handleBlur}
                onChange={handleChange}
                name={`medications[${key}].animalName`}
                value={values.medications[key].animalName}
              />
              <ErrorMessage
                component="div"
                className="error-message"
                name={`medications[${key}].animalName`}
              />
            </div>
          </GridItem>
          <GridItem sm={6}>
            <div
              className={`form-group ${
                basicConditional
                  && touched.medications[key].prescriptionNumber
                  && errors.medications[key].prescriptionNumber
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor={`prescriptionNumber-${key}`}>Prescription Number *</label>
              <input
                type="text"
                id={`prescriptionNumber-${key}`}
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                name={`medications[${key}].prescriptionNumber`}
                value={values.medications[key].prescriptionNumber}
              />
              <ErrorMessage
                component="div"
                className="error-message"
                name={`medications[${key}].prescriptionNumber`}
              />
            </div>
            <div
              className={`form-group ${
                basicConditional
                  && touched.medications[key].quantity
                  && errors.medications[key].quantity
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor={`quantity-${key}`}>Quantity - Number of vials *</label>
              <input
                type="number"
                id={`quantity-${key}`}
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                name={`medications[${key}].quantity`}
                value={values.medications[key].quantity}
              />
              <ErrorMessage
                component="div"
                className="error-message"
                name={`medications[${key}].quantity`}
              />
            </div>
          </GridItem>
          <GridItem sm={12}>
            <div
              className={`form-group ${
                basicConditional
                  && touched.medications[key].message
                  && errors.medications[key].message
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor={`message-${key}`}>Message *</label>
              <textarea
                type="text"
                id={`message-${key}`}
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                name={`medications[${key}].message`}
                value={values.medications[key].message}
              />
              <ErrorMessage
                component="div"
                className="error-message"
                name={`medications[${key}].message`}
              />
            </div>
          </GridItem>
        </Grid>
        {values.medications[key + 1] === undefined && <AddMedicationBtn />}
      </Card>
    );
  });
};

const RefillForm = formProps => (
  <StaticQuery
    query={graphql`
      query {
        allStrapiState {
          edges {
            node {
              name
              strapiId
            }
          }
        }
        allStrapiMedication {
          edges {
            node {
              name
              strapiId
              presentation
            }
          }
        }
      }
    `}
    render={(data) => {
      const medications = data.allStrapiMedication
        ? formatGraphqlData(data.allStrapiMedication)
        : [];
      const states = data.allStrapiState ? formatGraphqlData(data.allStrapiState) : [];

      return (
        <Form className="c-online-refills-form">
          <Grid justifyContent="center" alignItems="center">
            <GridItem md={8}>
              <Card>
                <h4>Customer Information</h4>
                <Grid>
                  <GridItem sm={12}>
                    <div
                      className={`form-group ${
                        formProps.touched.customerName && formProps.errors.customerName
                          ? 'form-group-invalid'
                          : ''
                      }`}
                    >
                      <label htmlFor="customerName">
                        Person placing the request // Full Name. *
                      </label>
                      <input
                        id="customerName"
                        type="text"
                        name="customerName"
                        value={formProps.values.customerName}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                      />
                      <ErrorMessage name="customerName" component="div" className="error-message" />
                    </div>
                  </GridItem>
                  <GridItem sm={12}>
                    <div
                      className={`form-group ${
                        formProps.touched.accountHolderName && formProps.errors.accountHolderName
                          ? 'form-group-invalid'
                          : ''
                      }`}
                    >
                      <label htmlFor="accountHolderName">Individual Account Holder Name. *</label>
                      <input
                        id="accountHolderName"
                        type="text"
                        name="accountHolderName"
                        value={formProps.values.accountHolderName}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="accountHolderName"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </GridItem>
                  <GridItem sm={6}>
                    <div
                      className={`form-group ${
                        formProps.touched.customerId && formProps.errors.customerId
                          ? 'form-group-invalid'
                          : ''
                      }`}
                    >
                      <label htmlFor="customerId">ZooPharm Customer ID. *</label>
                      <CustomerIdField
                        id="customerId"
                        type="text"
                        name="customerId"
                        formProps={formProps}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                        required
                      />
                      <ErrorMessage name="customerId" component="div" className="error-message" />
                    </div>
                  </GridItem>
                  <GridItem sm={6}>
                    <div
                      className={`form-group ${
                        formProps.touched.facility && formProps.errors.facility
                          ? 'form-group-invalid'
                          : ''
                      }`}
                    >
                      <label htmlFor="facility">Facility or Ranch Name. *</label>
                      <input
                        id="facility"
                        type="text"
                        name="facility"
                        value={formProps.values.facility}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                      />
                      <ErrorMessage name="facility" component="div" className="error-message" />
                    </div>
                  </GridItem>
                </Grid>
              </Card>
              <div className="get-id-from-customer-service">
                Your ZooPharm Customer ID, beginning with &ldquo;ZC&rdquo;, is required to proceed.
                {' '}
                It can be found in upper right corner of previous Packing Slip(s) or Invoice(s).
              </div>
              <Card>
                <h4>Shipping Information</h4>
                <Grid>
                  <GridItem sm={12}>
                    <div
                      className={`form-group ${
                        formProps.touched.addressLine1 && formProps.errors.addressLine1
                          ? 'form-group-invalid'
                          : ''
                      }`}
                    >
                      <label htmlFor="addressLine1">Address Line 1: *</label>
                      <input
                        id="addressLine1"
                        type="text"
                        name="addressLine1"
                        value={formProps.values.addressLine1}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                      />
                      <ErrorMessage name="addressLine1" component="div" className="error-message" />
                    </div>
                  </GridItem>
                  <GridItem sm={12}>
                    <div
                      className={`form-group ${
                        formProps.touched.addressLine2 && formProps.errors.addressLine2
                          ? 'form-group-invalid'
                          : ''
                      }`}
                    >
                      <label htmlFor="addressLine2">Address Line 2:</label>
                      <input
                        id="addressLine2"
                        type="text"
                        name="addressLine2"
                        value={formProps.values.addressLine2}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                      />
                      <ErrorMessage name="addressLine2" component="div" className="error-message" />
                    </div>
                  </GridItem>
                  <GridItem sm={8}>
                    <div
                      className={`form-group ${
                        formProps.touched.phoneNumber && formProps.errors.phoneNumber
                          ? 'form-group-invalid'
                          : ''
                      }`}
                    >
                      <label htmlFor="phoneNumber">Direct Dial Phone Number *</label>
                      <PhoneField
                        id="phoneNumber"
                        type="tel"
                        name="phoneNumber"
                        placeholder="Example: (555) 555-5555"
                        formProps={formProps}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                        required
                      />
                      <ErrorMessage name="phoneNumber" component="div" className="error-message" />
                    </div>
                  </GridItem>
                  <GridItem sm={4}>
                    <div
                      className={`form-group ${
                        formProps.touched.phoneExt && formProps.errors.phoneExt
                          ? 'form-group-invalid'
                          : ''
                      }`}
                    >
                      <label htmlFor="phoneExt">Ext.#</label>
                      <input
                        id="phoneExt"
                        type="tel"
                        name="phoneExt"
                        value={formProps.values.phoneExt}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                      />
                      <ErrorMessage name="phoneExt" component="div" className="error-message" />
                    </div>
                  </GridItem>
                  <GridItem sm={5}>
                    <div
                      className={`form-group ${
                        formProps.touched.city && formProps.errors.city ? 'form-group-invalid' : ''
                      }`}
                    >
                      <label htmlFor="city">City *</label>
                      <input
                        id="city"
                        type="text"
                        name="city"
                        value={formProps.values.city}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                      />
                      <ErrorMessage name="city" component="div" className="error-message" />
                    </div>
                  </GridItem>
                  <GridItem sm={3}>
                    <div
                      className={`form-group ${
                        formProps.touched.state && formProps.errors.state
                          ? 'form-group-invalid'
                          : ''
                      }`}
                    >
                      <label htmlFor="state">State *</label>

                      <select
                        id="state"
                        name="state"
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                        defaultValue=""
                      >
                        <option value="" disabled hidden>
                          Select state
                        </option>
                        {states.map((item, index) => (
                          <option value={item.strapiId} key={index.toString()}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage name="state" component="div" className="error-message" />
                    </div>
                  </GridItem>
                  <GridItem sm={4}>
                    <div
                      className={`form-group ${
                        formProps.touched.zip && formProps.errors.zip ? 'form-group-invalid' : ''
                      }`}
                    >
                      <label htmlFor="zip">Zip *</label>
                      <input
                        id="zip"
                        type="number"
                        name="zip"
                        value={formProps.values.zip}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                      />
                      <ErrorMessage name="zip" component="div" className="error-message" />
                    </div>
                  </GridItem>
                  <GridItem sm={12}>
                    <div
                      className={`form-group ${
                        formProps.touched.email && formProps.errors.email
                          ? 'form-group-invalid'
                          : ''
                      }`}
                    >
                      <label htmlFor="email">Email *</label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        value={formProps.values.email}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                      />
                      <ErrorMessage name="email" component="div" className="error-message" />
                    </div>
                  </GridItem>
                </Grid>
              </Card>
              <Card>
                <h4>Prescriber Information</h4>
                <Grid>
                  <GridItem sm={12}>
                    <div
                      className={`form-group ${
                        formProps.touched.veterinarianName && formProps.errors.veterinarianName
                          ? 'form-group-invalid'
                          : ''
                      }`}
                    >
                      <label htmlFor="veterinarianName">Veterinarian Name *</label>
                      <input
                        id="veterinarianName"
                        type="text"
                        name="veterinarianName"
                        value={formProps.values.veterinarianName}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="veterinarianName"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </GridItem>
                </Grid>
              </Card>
              <FieldArray
                name="medications"
                render={arrayHelpers => (
                  <MedicationSection
                    formProps={formProps}
                    medications={medications}
                    arrayFieldProps={arrayHelpers}
                  />
                )}
              />
              <div className="form-group">
                <button type="submit" className="c-button--primary float-right">
                  SUBMIT
                </button>
              </div>
              {formProps.status && (
                <div className="error-message">
                  <p>{formProps.status}</p>
                </div>
              )}
            </GridItem>
          </Grid>
        </Form>
      );
    }}
  />
);

class RefillFormPage extends React.Component {
  state = {
    isSubmitting: false,
    hasErrors: false,
  };

  handleSubmit = async (values, { setSubmitting }) => {
    this.setState({ isSubmitting: true, hasErrors: false });
    try {
      await Request.post('/onlinerefillrequests/', JSON.stringify(values), {
        headers: { 'Content-Type': 'application/json' },
      });
      setSubmitting(false);
      this.setState({ isSubmitting: false });
      navigate('/thank-you-refill/');
    } catch (error) {
      setSubmitting(false);
      this.setState({ isSubmitting: false, hasErrors: true });
    }
  };

  handleDismiss = () => {
    this.setState({ hasErrors: false });
  };

  render() {
    const { isSubmitting, hasErrors } = this.state;
    return (
      <div>
        {isSubmitting && <Loader text="Please wait ..." />}
        <Layout title="Online Refills" modalContent={<h1>hello</h1>}>
          <Hero backgroundImage="zoo-pharm" headline="Online Refills" />
          <section className="content-wrapper">
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={this.handleSubmit}
              render={formProps => <RefillForm {...formProps} />}
            />
          </section>
        </Layout>
        <Snackbar
          message="There was an error submitting the form."
          show={hasErrors}
          type="danger"
          handleDismiss={this.handleDismiss}
        />
      </div>
    );
  }
}
export default RefillFormPage;
