import React from 'react';
import PropTypes, { object } from 'prop-types';

const CustomerIdField = ({
  formProps,
  ...props
}) => {
  const transformValue = (formprops) => {
    const { values } = formprops;
    let { customerId } = values;
    if (customerId === '' && !formprops.errors.customerId) {
      formprops.setFieldError('customerId', 'Customer ID is a required field.');
    }
    if (customerId[0] !== 'Z' && customerId[1] !== 'C') {
      customerId = `ZC${customerId}`;
    }
    return customerId;
  };

  return (
    <input
      value={transformValue(formProps)}
      {...props}
    />
  );
};

CustomerIdField.propTypes = {
  formProps: PropTypes.oneOfType([object]),
};

CustomerIdField.defaultProps = {
  formProps: {},
};

export default CustomerIdField;
